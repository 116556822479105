@use 'global' as *;
.accordion_item {
  border-bottom: 1px solid var(--color-prime-gray);
}

.accordion_header {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 30px;
  padding-bottom: 1rem;
  padding-top: 1rem;

  h4 {
    margin-bottom: 0;
  }

  .accordion_label {
    @include label;

    margin-bottom: 10px;
  }

  &.has_content {
    &:hover,
    h4:hover {
      cursor: pointer;
    }
  }
}

.dropdown_icon {
  align-self: center;
  justify-self: flex-end;
  transform-origin: 50% 50%;
  transition: transform 0.2s;

  svg {
    height: 20px;
    width: 20px;
  }
}

.dropdown_open {
  transform: rotate(90deg);
}

.wrapper {
  overflow: hidden;
}

.accordion_panel {
  background-color: var(--color-cloud);
  overflow: hidden;
  padding: 1.25rem 1.25rem 2rem;

  @include media($medium-lowest, down) {
    padding: 1rem;
  }

  a {
    margin-top: 20px;
  }
}

.light_text {
  color: var(--color-white);
}

.active {
  max-height: 500px;
  transition: all 200ms linear;

  @include media($small-highest, down) {
    max-height: 1000px;
  }
}

.closed {
  max-height: 0;
  transition: all 200ms linear;
}

.arrow {
  margin-bottom: 0.5rem;
}
