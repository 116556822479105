@use 'global' as *;
// ---------------------------------------------------------

.container {
  background: var(--color-off-black);
  color: var(--color-white);
  padding-top: 80px;
  text-align: center;
}

.image_desktop {
  @include media($full-navigation, down) {
    display: none;
  }
}

.image_mobile {
  @include media($full-navigation) {
    display: none;
  }

  @include media($small-lowest, down) {
    margin-bottom: 1rem;
  }
}

// ---------------------------------------------------------

.card_container {
  @include media($full-navigation, down) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
    width: 100%;
  }

  @include media($small-lowest, down) {
    grid-template-columns: 1fr;
  }

  .card_description {
    align-items: center;
    padding-top: 1rem;
    text-align: center;

    @include media($small-lowest, $full-navigation) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
      text-align: left;
    }

    a {
      @include arrow($color: var(--color-prime-teal), $hover: var(--color-ocean));
    }
  }

  @include media($small-lowest, $full-navigation) {
    &:nth-of-type(2) {
      > div:nth-child(1) {
        order: 2;
      }
    }
  }
}
