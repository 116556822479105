@use 'global' as *;
// --------------------------------------------------------- / Job posting

.current_openings {
  margin: 80px 1rem;

  h2 {
    margin: 0 0 40px;
    text-align: center;
  }
}

// --------------------------------------------------------- / Filters

.select_input {
  > div:first-child {
    border: 1px solid var(--color-pebble);
    border-radius: 5px;
    font-family: var(--font-family-base);
    font-size: 18px;
    padding: 11px;
  }

  > div:nth-child(2) {
    font-family: var(--font-family-base);
    font-size: 18px;
  }

  svg {
    fill: var(--color-pebble) !important;
    right: 11px !important;
    top: 22px !important;
  }
}

.filters_container {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 400px;
  width: 100%;

  .total_jobs {
    font-family: var(--font-family-base);
    font-size: 18px;
    letter-spacing: 0;
    text-align: right;
    text-transform: lowercase;
  }

  > * {
    margin: 5px 0;
  }
}

.filters_header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  span {
    @include label;

    margin-bottom: 2px;
  }
}

.button_clear_filters {
  @include link;

  display: flex;
  font-family: var(--font-family-base);
  font-size: 18px;
  text-decoration: none;

  .close_icon {
    height: 25px;
    margin-top: -4px;
    width: 15px;

    svg {
      height: 12px;
      margin-left: 5px;
      width: 12px;
    }
  }
}

.button_view_all {
  margin-top: 1rem;
}

// --------------------------------------------------------- / Job postings list view

.jobs_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: auto;
  max-width: var(--global-max-width);

  @include media($medium) {
    align-items: flex-start;
    flex-direction: row;
    width: calc(100% - var(--global-padding) * 2);
  }
}

.results_container {
  flex: 5;
  width: 100%;
}

.jobs_list {
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 20px;
  width: 100%;
}

// --------------------------------------------------------- / Single job posting

.job_post {
  button {
    align-items: center;
    background-color: var(--color-warm-gray);
    border: none;
    border-radius: var(--global-border-radius);
    container-type: inline-size;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    min-height: 120px;
    position: relative;
    text-align: left;
    transition: all 250ms ease;
    width: 100%;

    .on_hover_apply {
      align-items: center;
      display: flex;
      flex-direction: row;
      opacity: 0%;
      position: absolute;
      right: 0;
      width: 100px;

      p {
        font-family: var(--font-family-headings-bold);
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
      }

      svg {
        height: 14px;
        margin-left: -5px;
        transition: all 250ms ease;
      }
    }

    @include hover {
      background-color: var(--color-limoncello);

      * {
        cursor: pointer;
      }

      .on_hover_apply {
        @container (min-width: 700px) {
          opacity: 100%;

          svg {
            transform: translateX(6px);
          }
        }
      }
    }
  }
}

.job_content {
  @include media($small-lowest) {
    align-items: center;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    text-wrap: balance;
    width: 100%;
  }

  .job_content_column {
    @include media($small-lowest) {
      align-items: center;
      flex-flow: wrap;
      flex-direction: row;
      margin: 10px 5px 5px;
    }

    p {
      @include body;

      display: inline-block;
      font-size: 18px;
      margin: 0;

      @include media($small-lowest) {
        display: block;
      }
    }
  }

  h5 {
    font-family: var(--font-family-headings-bold);
    font-size: 18px;
    margin: 0;
  }

  @container (min-width: 700px) {
    grid-template-columns: 1.4fr 0.8fr 1.4fr;
  }
}

// --------------------------------------------------------- / Notice

.notice {
  margin-top: 40px;
}

.label {
  @include label;

  margin-bottom: 6px;
}
