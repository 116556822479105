@use 'global' as *;
// ---------------------------------------------------------

.ping_pong_container {
  box-sizing: border-box;
  width: 100%;
}

// --------------------------------------------------------- / content - default is media on right

.ping_pong_content {
  display: grid;
  height: 100%;
  margin: auto;
  position: relative;
  width: 100%;

  // TODO Remove once h6 is eliminated from ping pong component

  h6 a {
    color: var(--color-lagoon);
    text-decoration: none;

    @include hover {
      @include link;

      color: var(--color-midnight);
    }
  }

  .label {
    @include label;
  }

  // Lighter links for darker backgrounds

  &.light h6 a {
    color: var(--color-prime-teal);
    text-decoration: none;

    @include hover {
      @include link;

      color: var(--color-ocean) !important;
    }
  }

  @include media($medium-lowest) {
    grid-template-columns: repeat(2, 1fr);
    max-width: var(--global-max-width);
  }
}

// --------------------------------------------------------- / media container (image or video)

.media_container {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  order: -1;

  img {
    height: auto;
  }

  @include media($medium-lowest) {
    height: 100%;
    order: 1;
  }

  @include media($large) {
    width: calc(100% + (100vw - var(--global-max-width)) / 2);
  }

  @include media($x-large) {
    max-width: 1200px;
  }
}

// --------------------------------------------------------- / text container

.text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  width: 100%;
  z-index: var(--z-index);

  @include media($small) {
    padding: 2rem;
  }

  @include media($large-highest) {
    padding: 0;
    padding-right: 6rem;
  }

  h1,
  h2 {
    text-wrap: balance;
  }

  // Lighter links for darker backgrounds

  button + a {
    margin-left: 1rem;
  }
}

.social_media {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;

  a {
    color: var(--color-ocean);
    width: 22px;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    @include hover {
      color: var(--color-prime-teal);
    }
  }
}

.dark_icons {
  a {
    color: var(--color-black);

    @include hover {
      color: var(--color-lagoon);
    }
  }
}

// --------------------------------------------------------- / class overrides for media on left

.media_on_left {
  order: 1;

  .media_container {
    height: auto;

    @include media($medium-lowest) {
      order: -1;
    }

    @include media($large, $x-large) {
      margin-left: calc((100vw - var(--global-max-width)) / -2);
    }

    @include media($x-large) {
      margin-left: -480px;
    }
  }

  .text_container {
    height: auto;

    @include media($medium, $large) {
      padding: 3rem;
    }

    @include media($large) {
      padding: 6rem 0 6rem 6rem;
    }
  }
}

// --------------------------------------------------------- Label styling

.label_container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  .label_logo {
    margin-bottom: 4px;
    margin-right: 8px;
  }

  .label_text {
    @include label;
  }
}
