@use 'global' as *;
.fullwidth_section {
  align-items: center;
  background-color: black;
  display: flex;
  height: clamp(150px, calc(100vw / 16 * 9), 92vh);
  justify-content: center;
  position: relative;
  transition: 100ms ease, background-color 0s;
  width: 100%;

  video {
    aspect-ratio: 16 / 9;
    max-height: 100%;
    width: clamp(150px, 100vw, 1920px);
  }
}

.fullwidth_closed {
  background-color: var(--color-limoncello);
  /* stylelint-disable length-zero-no-unit */
  height: 60px;
  overflow: hidden;

  video {
    display: none;
  }

  .close_icon_container {
    margin-right: -5px;
    position: absolute;
    transform: rotate(45deg) scale(0.9);

    svg {
      color: var(--color-off-black);
    }
  }
}

.video_help_text {
  @include label;

  color: var(--color-off-black);
  cursor: pointer;
  margin: 0 auto;
  max-width: 70%;

  @include media($x-small-highest) {
    max-width: 100%;
  }

  &:hover {
    color: var(--color-midnight);
  }
}

.close_icon_container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: 150ms ease-out;
}

.close_icon {
  align-items: center;
  border-radius: 50%;
  color: var(--color-cement);
  display: flex;
  height: 32px;
  justify-content: center;
  transition: color 200ms ease-out;
  width: 32px;

  svg {
    height: 14px;
    margin-top: -5px;
    width: 100%;
  }

  @include hover {
    background-color: var(--color-moss);
    color: var(--color-off-black);
    cursor: pointer;
  }
}
